import React, { useEffect } from "react";

const ImmigrationAssistance = ({ pageTitle }) => {
  useEffect(() => {
    document.title = `${pageTitle ?? ""} - GuidanceplusNI`;
  }, []);

  return (
    <>
      <div className="education-main-section">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="education-about-services">
                <div className="education-section-heading">
                  <h2>
                    Navigating Immigration with Ease: Your Trusted Partner for a New Beginning
                  </h2>
                </div>
                <p>
                  To immigrate is to relocate and reside in a country or area that is not one's
                  home. For a handful of reasons, the UK is a popular immigration site. It has a
                  thriving economy and a great labour market. The social and cultural standards of
                  the United Kingdom are impeccable, making it a primary target for aspiring
                  students worldwide. As an outcome, inhabitants and foreigners can enjoy a better
                  quality of life in the UK. According to UK government policy, the laws and
                  regulations about immigration are subject to periodic changes.
                </p>

                <p>
                  Brexit is the shorthand for the United Kingdom's (UK) decision to leave the
                  European Union (EU) on January 31, 2020. Free migration from EU nations was
                  terminated by Brexit, and the UK government substantially changed the country's
                  immigration laws following Brexit.
                </p>

                <p>
                  The UK Government's Policy Statement on the country's points-based immigration
                  system highlighted that it intends to build an economy with high salaries, high
                  aptitude, and high efficiency.
                </p>

                <p>
                  Establishing a framework that prioritizes one's capabilities rather than their
                  background serves the welfare of the entire United Kingdom. EU and non-EU citizens
                  are regarded equitably and those with the best skills and competencies have been
                  given priority since January 1, 2021. Thus the EU and non-EU citizens now have
                  identical prospects. These revised modifications paved the way for numerous visa
                  pathways, leave to remain, and permanent settlement available under the UK
                  immigration system.
                </p>

                <div className="education-section-heading">
                  <h2>WHAT WE BRING TO THE TABLE:</h2>
                </div>

                <p>
                  At Guidance Plus NI, we offer supervised immigration and strategic consulting
                  services. We will assist you with your UK business immigration needs, including
                  managing sponsor license applications, going through large volumes of UK visa
                  applications, auditing, training, and difficult legal proceedings. We tailor our
                  approach to suit your goals.
                </p>

                <p>
                  We help organisations achieve value using a cost-effective and novel approach to
                  overseas recruitment and improvement.
                </p>

                <div className="education-section-heading">
                  <h2>SPONSORSHIP DOCUMENTATION ASSISTANCE</h2>
                </div>
                <p>
                  Full competent assistance for UK employers of sponsored workers, including
                  managing all Home Office applications and ensuring compliance with sponsor
                  licensing conditions.
                </p>

                <ul>
                  <li>Sponsor licence application assistance</li>
                  <li>Certificates of Sponsorship documentation assistance </li>
                  <li>Skilled Worker sponsor licence assistance</li>
                  <li>Temporary Worker sponsor licence assistance</li>
                  <li>
                    Global Business Mobility sponsor licence assistance Scale Up Worker sponsor
                    licence assistance
                  </li>
                  <li>
                    Senior or Specialist Workers sponsorship assistance Sponsor licence compliance
                    support
                  </li>
                  <li>Sponsor licence renewal assistance</li>
                  <li>Sponsor licence suspension assistance</li>
                  <li>Sponsor licence revocation assistance</li>
                </ul>

                <div className="education-section-heading">
                  <h2>IMMIGRATION</h2>
                </div>
                <p>
                  Assure efficient risk mitigation for immigration compliance across your staff and
                  organization.
                </p>
                <ul>
                  <li>Right to Work Consultancy assistance</li>
                  <li>Immigration Audit assistance</li>
                  <li>Immigration Compliance Training assistance</li>
                  <li>SMS Level 1 User Training assistance</li>
                </ul>

                <div className="education-section-heading">
                  <h2>UK SKILLED WORKER & BUSINESS VISA DOCUMENTATION ASSISTANCE</h2>
                </div>
                <p>
                  Complete application processing and management services for UK work and business
                  visas.
                </p>
                <ul>
                  <li>Skilled Worker visa and documentation assistance</li>
                  <li>Self Sponsorship Visa and documentation assistance</li>
                  <li>Health & Care Worker visa and documentation assistance</li>
                  <li>Innovator Founder visa and documentation assistance</li>
                  <li>Senior or Specialist Worker (GBM) visa and documentation assistance</li>
                  <li>UK Expansion Worker (GBM) visa and documentation assistance</li>
                  <li>Graduate Trainee (GBM) visa and documentation assistance</li>
                  <li>Secondment Worker (GBM) visa and documentation assistance</li>
                  <li>Service Supplier (GBM) visa and documentation assistance</li>
                  <li>Scale Up visa and documentation assistance</li>
                  <li>International Sportsperson visa and documentation assistance</li>
                  <li>Creative Worker visa and documentation assistance</li>
                  <li>Seasonal Worker visa and documentation assistance</li>
                  <li>Charity Worker visa and documentation assistance</li>
                  <li>Religious Worker visa and documentation assistance</li>
                  <li>International Agreement visa and documentation assistance</li>
                  <li>Authorised Government Exchange visa and documentation assistance</li>
                  <li>Youth Mobility Scheme visa and documentation assistance</li>
                </ul>

                <div className="education-section-heading">
                  <h2>OTHER SERVICES</h2>
                </div>

                <ul>
                  <li>Graduate visa and documentation assistance</li>
                  <li>High Potential Individual visa and documentation assistance</li>
                  <li>Global Talent visa and documentation assistance</li>
                </ul>

                <div className="education-section-heading">
                  <h2>WHY CHOOSE GUIDANCE PLUS NI?</h2>
                </div>

                <p>
                  Immigration can be stressful and perplexing, particularly if you're unsure of
                  where to look for support and guidance. We are aware that there is a vast amount
                  of information available and that it is frequently challenging to distinguish what
                  is authentic. Here's where we can assist. We are a reputable firm that focuses on
                  UK immigration and is available to help with any kind of trouble. We can empathize
                  and connect with your worries. UK citizenship, working visas, point-based
                  applications, and there goes the list of areas that we can advise on.
                </p>

                <p>
                  In addition to helping you through the procedure, our main goal is to alleviate
                  your tension and anxiety. Any matter we take on is handled effectively, and we
                  constantly make sure to remain attentive to the demands and concerns of our
                  clients. Every case is heard and dealt with separately. We are a fast-growing and
                  established choice among individuals seeking legal assistance with
                  immigration-related matters. Whether you are applying or need guidance on what to
                  do next, we can help you. We take great pride in our potential to provide
                  first-rate solutions and a welcoming, dependable service by respectfully handling
                  complex immigration or Visa-related challenges for our clients.
                </p>

                <p>
                  Are you currently handling an application that was denied or requires a renewal?
                </p>

                <p>
                  Do you wish to move abroad or are you in need of a placement assistance or student
                  visa assistance?
                </p>

                <p>Are the approaching deadlines overwhelming you?</p>
                <p>If so, we are here for you, every step of the way!</p>
                <p>Connecting careers and building futures.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ImmigrationAssistance;
