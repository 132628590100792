import React, { useEffect } from "react";
import { Fancybox } from "@fancyapps/ui";

import galleryImg1 from "../extra-images/modern-gallery-img1.jpg";
import galleryImg2 from "../extra-images/modern-gallery-img2.jpg";
import galleryImg3 from "../extra-images/modern-gallery-img3.jpg";
import galleryImg4 from "../extra-images/modern-gallery-img4.jpg";
import galleryImg5 from "../extra-images/modern-gallery-img5.jpg";
import galleryImg6 from "../extra-images/modern-gallery-img6.jpg";

const Gallery = ({ pageTitle }) => {
  useEffect(() => {
    document.title = `${pageTitle ?? ""} - GuidanceplusNI`;
  }, []);

  useEffect(() => {
    // Initialize Fancybox
    Fancybox.bind("[data-fancybox]", {
      // Your custom options
    });

    // Cleanup on unmount
    return () => {
      Fancybox.destroy();
    };
  }, []);

  return (
    <div className="education-main-section">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="education-gallery education-modern-gallery education-classic-gallery">
              <ul>
                <li>
                  <figure>
                    <a data-fancybox-group="group" href={galleryImg1} className="fancybox">
                      <img src={galleryImg1} alt="" />
                      <i className="icon-enlarge"></i>
                    </a>
                    <figcaption>
                      <span>12 Photos</span>
                    </figcaption>
                  </figure>
                </li>
                <li>
                  <figure>
                    <a data-fancybox-group="group" href={galleryImg2} className="fancybox">
                      <img src={galleryImg2} alt="" />
                      <i className="icon-enlarge"></i>
                    </a>
                    <figcaption>
                      <span>12 Photos</span>
                    </figcaption>
                  </figure>
                </li>
                <li>
                  <figure>
                    <a data-fancybox-group="group" href={galleryImg3} className="fancybox">
                      <img src={galleryImg3} alt="" />
                      <i className="icon-enlarge"></i>
                    </a>
                    <figcaption>
                      <span>12 Photos</span>
                    </figcaption>
                  </figure>
                </li>
                <li>
                  <figure>
                    <a data-fancybox-group="group" href={galleryImg4} className="fancybox">
                      <img src={galleryImg4} alt="" />
                      <i className="icon-enlarge"></i>
                    </a>
                    <figcaption>
                      <span>12 Photos</span>
                    </figcaption>
                  </figure>
                </li>
                <li>
                  <figure>
                    <a data-fancybox-group="group" href={galleryImg5} className="fancybox">
                      <img src={galleryImg5} alt="" />
                      <i className="icon-enlarge"></i>
                    </a>
                    <figcaption>
                      <span>12 Photos</span>
                    </figcaption>
                  </figure>
                </li>
                <li>
                  <figure>
                    <a data-fancybox-group="group" href={galleryImg6} className="fancybox">
                      <img src={galleryImg6} alt="" />
                      <i className="icon-enlarge"></i>
                    </a>
                    <figcaption>
                      <span>12 Photos</span>
                    </figcaption>
                  </figure>
                </li>
                <li>
                  <figure>
                    <a data-fancybox-group="group" href={galleryImg1} className="fancybox">
                      <img src={galleryImg1} alt="" />
                      <i className="icon-enlarge"></i>
                    </a>
                    <figcaption>
                      <span>12 Photos</span>
                    </figcaption>
                  </figure>
                </li>
                <li>
                  <figure>
                    <a data-fancybox-group="group" href={galleryImg2} className="fancybox">
                      <img src={galleryImg2} alt="" />
                      <i className="icon-enlarge"></i>
                    </a>
                    <figcaption>
                      <span>12 Photos</span>
                    </figcaption>
                  </figure>
                </li>
                <li>
                  <figure>
                    <a data-fancybox-group="group" href={galleryImg3} className="fancybox">
                      <img src={galleryImg3} alt="" />
                      <i className="icon-enlarge"></i>
                    </a>
                    <figcaption>
                      <span>12 Photos</span>
                    </figcaption>
                  </figure>
                </li>
                <li>
                  <figure>
                    <a data-fancybox-group="group" href={galleryImg4} className="fancybox">
                      <img src={galleryImg4} alt="" />
                      <i className="icon-enlarge"></i>
                    </a>
                    <figcaption>
                      <span>12 Photos</span>
                    </figcaption>
                  </figure>
                </li>
                <li>
                  <figure>
                    <a data-fancybox-group="group" href={galleryImg5} className="fancybox">
                      <img src={galleryImg5} alt="" />
                      <i className="icon-enlarge"></i>
                    </a>
                    <figcaption>
                      <span>12 Photos</span>
                    </figcaption>
                  </figure>
                </li>
                <li>
                  <figure>
                    <a data-fancybox-group="group" href={galleryImg6} className="fancybox">
                      <img src={galleryImg6} alt="" />
                      <i className="icon-enlarge"></i>
                    </a>
                    <figcaption>
                      <span>12 Photos</span>
                    </figcaption>
                  </figure>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Gallery;
