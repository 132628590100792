import React, { useState } from "react";
// import Slider from "react-slick";
import { Outlet, useLocation } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { routes } from "../PageRoutes.js";

import logo from "../images/guidanceplusni.png";
import banner1 from "../extra-images/immigration-1.webp";
import banner2 from "../extra-images/immigration-2.jpg";
import banner3 from "../extra-images/immigration-3.jpg";
import banner4 from "../extra-images/immigration-4.jpg";
import ilpaLogo from "../images/ILPA-Member-logo.jpg";
import iaaLogo from "../images/IAA_Logo.jpg";
import footerLogo from "../images/guidanceplusni-transparent.png";

import newslatter from "../extra-images/news-latter-img.jpg";
import { COURSE_DETAILS } from "./CourseDetailsData";
import { COUNTRY_DETAILS } from "./CountryDetailsData";

function shuffleArray() {
  let newArr = [...COURSE_DETAILS];
  for (let i = newArr.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [newArr[i], newArr[j]] = [newArr[j], newArr[i]];
  }
  return newArr;
}

const Layout = () => {
  const location = useLocation();
  const isHome = location.pathname === "/";
  const pageRouteFound = routes.find((r) => `/${r.path}` === location.pathname);
  const [showHideNav1, setShowHideNav1] = useState(false);
  const [showHideNav2, setShowHideNav2] = useState(false);
  const [email, setEmail] = useState("Type Here");
  const [message, setMessage] = useState("");

  const shuffledCourses = shuffleArray();
  const selectedCourses = shuffledCourses.slice(0, 6);

  const settings = {
    dots: false, // Show navigation dots
    infinite: true, // Infinite loop
    speed: 500, // Transition speed
    slidesToShow: 1, // Show only 1 slide at a time
    slidesToScroll: 1, // Scroll 1 slide at a time
    autoplay: true, // Auto-slide images
    autoplaySpeed: 3000, // 3 seconds per slide
    arrows: false, // Hide navigation arrows
  };

  const slides = [
    {
      image: banner1,
      title: "Unlock Global Opportunities with the Best Immigration Services!",
    },
    {
      image: banner3,
      title: "Simplifying Your Path to a New Life!",
    },
    {
      image: banner4,
      title: "From Application to Approval – We’ve Got You Covered!",
    },
    {
      image: banner2,
      title: "Dream. Move. Succeed.",
    },
  ];

  const handleFocus = () => {
    if (email === "Type Here") {
      setEmail("");
    }
  };

  const handleBlur = () => {
    if (email.trim() === "") {
      setEmail("Type Here");
    }
  };

  const handleSubmit = async () => {
    setMessage("");
    if (email === "" || email === "Type Here") {
      setMessage("Please enter a valid email.");
      return;
    }

    try {
      const response = await fetch("https://www.guidanceplusni.com/api/subscribe.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email }),
      });

      if (response.ok) {
        setMessage("✅ Subscription successful!");
        setEmail("Type Here");
      } else {
        setMessage("❌ Subscription failed. Try again.");
      }
    } catch (error) {
      setMessage("❌ Network error. Please try again.");
    }
  };

  return (
    <>
      <div className="education-main-wrapper">
        <header id="education-header" className="education-header-one">
          <div className="education-top-strip education-bgcolor">
            <div className="container">
              <div className="row">
                <aside className="col-md-6">
                  <ul className="education-userinfo">
                    <li>
                      <a href="tel:+447507272479">
                        <i className="fa fa-phone"></i> +(44) 7507272479
                      </a>
                    </li>
                    <li>
                      <a href="mailto:info@guidanceplusni.com">
                        <i className="fa fa-envelope"></i> info@guidanceplusni.com
                      </a>
                    </li>
                  </ul>
                </aside>
                <aside className="col-md-6">
                  <ul className="education-user-section">
                    {/* <li className="header-scoail-icon">
                      <a href="/book-an-appointment" className="appointment-btn">
                        <i className="fa fa-calendar"></i> Book An Appointment
                      </a>
                    </li> */}

                    <li className="header-scoail-icon">
                      <a
                        href="https://www.facebook.com/profile.php?id=61558535126017"
                        target="_blank"
                      >
                        <i className="fa fa-facebook-square"></i>
                      </a>
                    </li>

                    <li className="header-scoail-icon">
                      <a
                        href="https://www.instagram.com/guidanceplusni?igsh=NXB2Y3R5cjB4ZWpu"
                        target="_blank"
                      >
                        <i className="fa fa-instagram"></i>
                      </a>
                    </li>
                  </ul>
                </aside>
              </div>
            </div>
          </div>

          <div className="education-main-header">
            <div className="container-fluid">
              <div className="row">
                {/* <div className="col-md-12">
                  <div className="scrolling-message">Website Under Construction</div>
                </div> */}
                <aside className="col-md-4 col-xs-2">
                  <nav className="navbar navbar-default">
                    <div className="navbar-header">
                      <button
                        type="button"
                        className="navbar-toggle collapsed"
                        data-toggle="collapse"
                        data-target="#navbar-collapse-1"
                        aria-expanded="true"
                        onClick={() => setShowHideNav1(!showHideNav1)}
                      >
                        <span className="sr-only">Toggle navigation</span>
                        <span className="icon-bar"></span>
                        <span className="icon-bar"></span>
                        <span className="icon-bar"></span>
                      </button>
                    </div>
                    <div
                      className={`collapse navbar-collapse ${showHideNav1 ? "in" : ""}`}
                      id="navbar-collapse-1"
                    >
                      <ul className="nav navbar-nav">
                        <li className={isHome ? "active" : ""}>
                          <a href="/">Home</a>
                        </li>
                        <li className={pageRouteFound?.path === "about-us" ? "active" : ""}>
                          <a href="/about-us">About us</a>
                        </li>

                        <li>
                          <a href="/global-education">
                            Global <br />
                            Education
                          </a>
                          <ul className="education-dropdown-menu">
                            <li>
                              <a href="/study-abroad-courses">Courses</a>
                            </li>

                            <li>
                              <a href="/study-abroad-countries">Countries</a>
                            </li>
                          </ul>
                        </li>

                        {/* <li className={pageRouteFound?.path === "global-education" ? "active" : ""}>
                          <a href="/global-education">
                            Global <br />
                            Education
                          </a>
                        </li> */}
                        <li
                          className={
                            pageRouteFound?.path === "immigration-assistance" ? "active" : ""
                          }
                        >
                          <a href="/immigration-assistance">
                            Immigration
                            <br />
                            Assistance
                          </a>
                        </li>

                        {/* <li
                          className={
                            pageRouteFound?.path === "study-abroad-courses" ? "active" : ""
                          }
                        >
                          <a href="/study-abroad-courses">Courses</a>
                        </li>
                        <li>
                          <a href="#">Country</a>
                          <ul className="education-dropdown-menu">
                            {COUNTRY_DETAILS.map((country) => (
                              <li>
                                <a href={country?.link}>{country?.name}</a>
                              </li>
                            ))}
                          </ul>
                        </li> */}
                      </ul>
                    </div>
                  </nav>
                </aside>
                <aside className="col-md-3 col-xs-8 no-pad logo-outer">
                  <a href="/" className="education-logo">
                    <img src={logo} alt="Guiguidanceplusni" />
                  </a>
                </aside>
                <aside className="col-md-4 col-xs-2">
                  <nav className="navbar navbar-default">
                    <div className="navbar-header">
                      <button
                        type="button"
                        className="navbar-toggle collapsed"
                        data-toggle="collapse"
                        data-target="#navbar-collapse-2"
                        aria-expanded="true"
                        onClick={() => setShowHideNav2(!showHideNav2)}
                      >
                        <span className="sr-only">Toggle navigation</span>
                        <span className="icon-bar"></span>
                        <span className="icon-bar"></span>
                        <span className="icon-bar"></span>
                      </button>
                    </div>
                    <div
                      className={`collapse navbar-collapse ${showHideNav2 ? "in" : ""}`}
                      id="navbar-collapse-2"
                    >
                      <ul className="nav navbar-nav header-right-menu">
                        <li>
                          <a href="#">Services</a>
                          <ul className="education-dropdown-menu">
                            <li>
                              <a href="/">World wide university admission</a>
                            </li>
                            <li>
                              <a href="/">Skilled worker placement</a>
                              <ul className="education-dropdown-menu">
                                <li>
                                  <a href="/services/skilled-worker-placement/employer">Employer</a>
                                </li>
                                <li>
                                  <a href="/">Jobseeker</a>
                                </li>
                              </ul>
                            </li>
                            <li>
                              <a href="/">Visa Application Assistance</a>
                            </li>
                            <li>
                              <a href="/">Overseas NMC Registration Assistance</a>
                            </li>
                            <li>
                              <a href="/services/oci-application-assistance">
                                OCI Application Assistance
                              </a>
                            </li>
                          </ul>
                        </li>
                        <li>
                          <a href="/recruitment">Recruitment</a>
                        </li>
                        <li>
                          <a href="/contact-us">Contact</a>
                        </li>
                      </ul>
                    </div>
                  </nav>
                </aside>
              </div>
            </div>
          </div>
        </header>

        {isHome ? (
          <div className="banner-slider">
            <Slider {...settings}>
              {slides.map((slide, index) => (
                <div key={index} className="banner-slide">
                  <img src={slide.image} alt={`Slide ${index}`} className="banner-image" />
                  <div className="education-banner-caption">
                    <span className="education-bgcolor banner-title">{slide.title}</span>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        ) : (
          pageRouteFound && (
            <div className="education-subheader">
              <span className="subheader-transparent"></span>
              <div className="container">
                <div className="row">
                  <div className="col-md-12">
                    <div className="education-subheader-title">
                      <h1>{pageRouteFound?.element?.props?.pageTitle ?? ""}</h1>
                    </div>
                    <ul className="education-breadcrumb">
                      <li>
                        <a href="/">Home</a>
                      </li>
                      <li>
                        <i className="fa fa-long-arrow-right"></i>
                      </li>
                      <li className="active">{pageRouteFound?.element?.props?.pageTitle ?? ""}</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          )
        )}

        {isHome || pageRouteFound ? (
          <div className="education-main-content">
            <Outlet />

            <div className="education-main-section">
              <div className="container">
                <div className="row">
                  <div className="col-md-12">
                    <div className="education-subscribe-newsletter">
                      {/* <img src={newslatter} alt="Guiguidanceplusni" /> */}
                      <div className="education-newslatter-text">
                        <h2>Subscribe To Our Newsletter</h2>
                        <form onSubmit={(e) => e.preventDefault()}>
                          <input
                            type="text"
                            value={email}
                            onFocus={handleFocus}
                            onBlur={handleBlur}
                            onChange={(e) => setEmail(e.target.value)}
                          />
                          <label>
                            <input type="submit" value="Subscribe Now" onClick={handleSubmit} />
                          </label>
                        </form>
                        {message && <p>{message}</p>}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <Outlet />
        )}

        <footer id="education-footer" className="education-footer-one">
          <span className="education-footer-pattren"></span>

          <div className="education-footer-widget">
            <div className="container">
              <div className="row">
                <aside className="col-md-4 widget widget_contact_info">
                  <a href="/" className="education-footer-logo">
                    <img src={footerLogo} alt="Guiguidanceplusni" />
                  </a>
                  <ul>
                    <li>
                      <i className="education-color fa fa-home"></i> 115 Cregagh Road Belfast BT6
                      0LA 028 9013 6332
                    </li>

                    <li>
                      <i className="education-color fa fa-phone"></i>
                      <a href="tel:02890136332">028 9013 6332</a>
                    </li>

                    <li>
                      <i className="education-color fa fa-mobile"></i>
                      <a href="tel:+447507272479">+44 7507 272479</a>
                    </li>

                    <li>
                      <i className="education-color fa fa-envelope"></i>
                      <a href="mailto:info@guidanceplusni.com">info@guidanceplusni.com </a>
                    </li>
                  </ul>
                </aside>

                <aside className="col-md-4 widget widget_contact_info">
                  <div className="education-footer-title">
                    <h4>Opening Hours</h4>
                  </div>
                  <ul>
                    <li>
                      <i className="education-color fa fa-clock-o"></i> Weekdays <br />
                      Monday to Friday
                      <br /> 9:15 AM to 5:30 PM
                    </li>

                    <li>
                      <i className="education-color fa fa-clock-o"></i> Weekends <br />
                      Saturday
                      <br /> 10:30 AM to 2:00 PM
                    </li>

                    <li>
                      <i className="education-color fa fa-clock-o"></i> Sunday - Holiday
                    </li>
                  </ul>
                </aside>

                <aside className="col-md-4 ">
                  <img src={iaaLogo} alt="Guiguidanceplusni" className="footer-thumb" />
                  <br />
                  <br />
                  <img src={ilpaLogo} alt="Guiguidanceplusni" className="footer-thumb" />
                </aside>
              </div>
            </div>
          </div>

          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="education-copyright">
                  <ul>
                    <li>
                      <a href="/privacy-policy">Privacy Policy</a>
                    </li>
                    <li>
                      <a href="/terms-and-conditions">Terms and Conditions</a>
                    </li>
                    <li>
                      <a href="/">Sitemap</a>
                    </li>
                  </ul>
                  <a href="#" className="education-back-top">
                    <i className="fa fa-angle-up"></i>
                  </a>
                  <p className="text-center">
                    Guidance Plus NI LTD is a company registered in Northern Ireland, No: NI696438.
                    Authorised and Regulated by The Immigration Advice Authority (Previously known
                    as OISC), Reg No: F202433813. All Rights Reserved.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </footer>

        <div className="clearfix"></div>
      </div>
    </>
  );
};

export default Layout;
