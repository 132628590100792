import React, { useEffect } from "react";

const PrivacyPolicy = ({ pageTitle }) => {
  useEffect(() => {
    document.title = `${pageTitle ?? ""} - GuidanceplusNI`;
  }, []);

  return (
    <>
      <div className="education-main-section">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="education-about-services">
                <p>
                  This Privacy Policy applies between you, the User of this Website, and GUIDANCE
                  PLUS NI LTD, the owner and provider of this Website. GUIDANCE PLUS NI LTD takes
                  the privacy of your information very seriously. This Privacy Policy applies to our
                  use of any and all Data collected by us or provided by you in relation to your use
                  of the Website.
                </p>
                <p>
                  This Privacy Policy should be read alongside, and in addition to, our Terms and
                  Conditions, which can be found at: www. guidanceplusni.com.
                </p>

                <h6>Please read this Privacy Policy carefully.</h6>

                <h5>Definitions and Interpretation</h5>

                <ol>
                  <li>
                    <p>In this Privacy Policy, the following definitions are used:</p>
                    <table className="table table-bordered">
                      <tbody>
                        <tr>
                          <th>Data</th>
                          <td>
                            collectively all information that you submit to GUIDANCE PLUS NI LTD via
                            the Website. This definition incorporates, where applicable, the
                            definitions provided in the Data Protection Laws;
                          </td>
                        </tr>
                        <tr>
                          <th>Data Protection Laws</th>
                          <td>
                            any applicable law relating to the processing of personal Data,
                            including but not limited to the GDPR, and any national implementing and
                            supplementary laws, regulations and secondary legislation;
                          </td>
                        </tr>

                        <tr>
                          <th>GDPR</th>
                          <td>the UK General Data Protection Regulation;</td>
                        </tr>
                        <tr>
                          <th>GUIDANCE PLUS NI LTD, we or us</th>
                          <td>
                            GUIDANCE PLUS NI LTD, a company incorporated in with registered number
                            07507272479 whose registered office is at GUIDANCE PLUS NI LTD, 115
                            CREGAGH ROAD, BELFAST, BT6 0LA;
                          </td>
                        </tr>
                        <tr>
                          <th>User or you</th>
                          <td>
                            any third party that accesses the Website and is not either (i) employed
                            by GUIDANCE PLUS NI LTD and acting in the course of their employment or
                            (ii) engaged as a consultant or otherwise providing services to GUIDANCE
                            PLUS NI LTD and accessing the Website in connection with the provision
                            of such services; and
                          </td>
                        </tr>
                        <tr>
                          <th>Website</th>
                          <td>
                            the website that you are currently using, www.guidanceplusni.com, and
                            any sub-domains of this site unless expressly excluded by their own
                            terms and conditions.
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </li>
                  <li>
                    <p>
                      In this Privacy Policy, unless the context requires a different
                      interpretation:
                    </p>
                    <ol className="alphabetic-list">
                      <li>the singular includes the plural and vice versa;</li>
                      <li>
                        references to sub-clauses, clauses, schedules or appendices are to
                        sub-clauses, clauses, schedules or appendices of this Privacy Policy;
                      </li>
                      <li>
                        a reference to a person includes firms, companies, government entities,
                        trusts and partnerships;
                      </li>
                      <li>"including" is understood to mean "including without limitation";</li>
                      <li>
                        reference to any statutory provision includes any modification or amendment
                        of it;
                      </li>
                      <li>
                        the headings and sub-headings do not form part of this Privacy Policy.
                      </li>
                    </ol>
                  </li>
                </ol>

                <h5>Scope of this Privacy Policy</h5>

                <ol start="3">
                  <li>
                    <p>
                      This Privacy Policy applies only to the actions of GUIDANCE PLUS NI LTD and
                      Users with respect to this Website. It does not extend to any websites that
                      can be accessed from this Website including, but not limited to, any links we
                      may provide to social media websites.
                    </p>
                  </li>
                  <li>
                    <p>
                      For purposes of the applicable Data Protection Laws, GUIDANCE PLUS NI LTD is
                      the "data controller". This means that GUIDANCE PLUS NI LTD determines the
                      purposes for which, and the manner in which, your Data is processed.
                    </p>
                  </li>
                </ol>

                <h5>Data Collected</h5>

                <ol start="5">
                  <li>
                    We may collect the following Data, which includes personal Data, from you:
                    <ol>
                      <li>name;</li>
                      <li>date of birth;</li>
                      <li>gender;</li>
                      <li>job title;</li>
                      <li>profession;</li>
                      <li>contact Information such as email addresses and telephone numbers; </li>
                      <li>
                        demographic information such as postcode, preferences and interests; h.
                        financial information such as credit / debit card numbers;
                      </li>
                    </ol>
                    <p>in each case, in accordance with this Privacy Policy.</p>
                  </li>
                </ol>

                <h5>How We Collect Data</h5>

                <ol start="6">
                  <li>
                    We collect Data in the following ways:
                    <ol>
                      <li>data is given to us by you; and</li>
                      <li>data is collected automatically.</li>
                    </ol>
                  </li>
                </ol>

                <h5>Data That is Given to Us by You</h5>

                <ol start="7">
                  <li>
                    <p>
                      GUIDANCE PLUS NI LTD will collect your Data in a number of ways, for example:
                    </p>
                    <ol>
                      <li>
                        when you contact us through the Website, by telephone, post, e-mail or
                        through any other means;
                      </li>
                      <li>when you use our services;</li>
                    </ol>
                    <p>in each case, in accordance with this Privacy Policy.</p>
                  </li>
                </ol>

                <h5>Data That is Collected Automatically</h5>

                <ol start="8">
                  <li>
                    <p>
                      To the extent that you access the Website, we will collect your Data
                      automatically, for example:
                    </p>
                    <ol>
                      <li>
                        we automatically collect some information about your visit to the Website.
                        This information helps us to make improvements to Website content and
                        navigation, and includes your IP address, the date, times and frequency with
                        which you access the Website and the way you use and interact with its
                        content.
                      </li>
                    </ol>
                  </li>
                </ol>

                <h5>Our Use of Data</h5>
                <ol start="9">
                  <li>
                    <p>
                      Any or all of the above Data may be required by us from time to time in order
                      to provide you with the best possible service and experience when using our
                      Website. Specifically, Data may be used by us for the following reasons:
                    </p>
                    <ol>
                      <li>internal record keeping;</li>
                    </ol>
                    <p>in each case, in accordance with this Privacy Policy.</p>
                  </li>
                  <li>
                    We may use your Data for the above purposes if we deem it necessary to do so for
                    our legitimate interests. If you are not satisfied with this, you have the right
                    to object in certain circumstances (see the section headed "Your rights" below).
                  </li>
                </ol>

                <h5>Who We Share Data With</h5>
                <ol start="11">
                  <li>
                    <p>
                      We may share your Data with the following groups of people for the following
                      reasons:
                    </p>
                    <ol>
                      <li>
                        any of our group companies or affiliates - As part of the required service
                        requested by the client.;
                      </li>
                      <li>
                        our employees, agents and/or professional advisors - As part of the required
                        service requested by the client.;
                      </li>
                      <li>
                        relevant authorities - As part of the required service requested by the
                        client.;
                      </li>
                    </ol>
                    <p>in each case, in accordance with this Privacy Policy.</p>
                  </li>
                </ol>

                <h5>Keeping Data Secure</h5>

                <ol start="12">
                  <li>
                    <p>
                      We will use technical and organisational measures to safeguard your Data, for
                      example:
                    </p>
                    <ol>
                      <li>
                        access to your account is controlled by a password and a user name that is
                        unique to you.
                      </li>
                      <li>we store your Data on secure servers.</li>
                      <li>
                        payment details are encrypted using SSL technology (typically you will see a
                        lock icon or green address bar (or both) in your browser when we use this
                        technology.
                      </li>
                    </ol>
                  </li>

                  <li>
                    <p>
                      Technical and organisational measures include measures to deal with any
                      suspected data breach. If you suspect any misuse or loss or unauthorised
                      access to your Data, please let us know immediately by contacting us via this
                      e-mail address: ceo@guidanceplusni.com.
                    </p>
                  </li>

                  <li>
                    <p>
                      If you want detailed information from Get Safe Online on how to protect your
                      information and your computers and devices against fraud, identity theft,
                      viruses and many other online problems, please visit www.getsafeonline.org.
                      Get Safe Online is supported by HM Government and leading businesses.
                    </p>
                  </li>
                </ol>

                <h5>Keeping Data Secure</h5>

                <ol start="15">
                  <li>
                    <p>
                      Unless a longer retention period is required or permitted by law, we will only
                      hold your Data on our systems for the period necessary to fulfil the purposes
                      outlined in this Privacy Policy or until you request that the Data be deleted.
                    </p>
                  </li>

                  <li>
                    <p>
                      Even if we delete your Data, it may persist on backup or archival media for
                      legal, tax or regulatory purposes.
                    </p>
                  </li>
                </ol>

                <h5>Your Rights</h5>
                <ol start="17">
                  <li>
                    <p>You have the following rights in relation to your Data:</p>
                    <ol>
                      <li>
                        <b>Right to access</b> - the right to request (i) copies of the information
                        we hold about you at any time, or (ii) that we modify, update or delete such
                        information. If we provide you with access to the information we hold about
                        you, we will not charge you for this, unless your request is "manifestly
                        unfounded or excessive." Where we are legally permitted to do so, we may
                        refuse your request. If we refuse your request, we will tell you the reasons
                        why.
                      </li>
                      <li>
                        <b>Right to correct</b> - the right to have your Data rectified if it is
                        inaccurate or incomplete.
                      </li>
                      <li>
                        <b>Right to erase</b> - the right to request that we delete or remove your
                        Data from our systems.
                      </li>
                      <li>
                        <b>Right to restrict our use of your Data</b> - the right to "block" us from
                        using your Data or limit the way in which we can use it.
                      </li>
                      <li>
                        <b>Right to data portability</b> - the right to request that we move, copy
                        or transfer your Data.
                      </li>
                      <li>
                        <b>Right to object</b> - the right to object to our use of your Data
                        including where we use it for our legitimate interests.
                      </li>
                    </ol>
                  </li>

                  <li>
                    <p>
                      To make enquiries, exercise any of your rights set out above, or withdraw your
                      consent to the processing of your Data (where consent is our legal basis for
                      processing your Data), please contact us via this e-mail address:
                      ceo@guidanceplusni.com.
                    </p>
                  </li>

                  <li>
                    <p>
                      If you are not satisfied with the way a complaint you make in relation to your
                      Data is handled by us, you may be able to refer your complaint to the relevant
                      data protection authority. For the UK, this is the Information Commissioner's
                      Office (ICO). The ICO's contact details can be found on their website at
                      https://ico.org.uk/.
                    </p>
                  </li>

                  <li>
                    <p>
                      It is important that the Data we hold about you is accurate and current.
                      Please keep us informed if your Data changes during the period for which we
                      hold it.
                    </p>
                  </li>
                </ol>

                <h5>Links to Other Websites</h5>
                <ol start="21">
                  <li>
                    <p>
                      This Website may, from time to time, provide links to other websites. We have
                      no control over such websites and are not responsible for the content of these
                      websites. This Privacy Policy does not extend to your use of such websites.
                      You are advised to read the Privacy Policy or statement of other websites
                      prior to using them.
                    </p>
                  </li>
                </ol>

                <h5>Changes of Business Ownership and Control</h5>
                <ol start="22">
                  <li>
                    <p>
                      GUIDANCE PLUS NI LTD may, from time to time, expand or reduce our business and
                      this may involve the sale and /or the transfer of control of all or part of
                      GUIDANCE PLUS NI LTD. Data provided by Users will, where it is relevant to any
                      part of our business so transferred, be transferred along with that part and
                      the new owner or newly controlling party will, under the terms of this Privacy
                      Policy, be permitted to use the Data for the purposes for which it was
                      originally supplied to us.
                    </p>
                  </li>

                  <li>
                    <p>
                      We may also disclose Data to a prospective purchaser of our business or any
                      part of it.
                    </p>
                  </li>

                  <li>
                    <p>
                      In the above instances, we will take steps with the aim of ensuring your
                      privacy is protected.
                    </p>
                  </li>
                </ol>

                <h5>General</h5>
                <ol start="25">
                  <li>
                    <p>
                      You may not transfer any of your rights under this Privacy Policy to any other
                      person. We may transfer our rights under this Privacy Policy where we
                      reasonably believe your rights will not be affected.
                    </p>
                  </li>

                  <li>
                    <p>
                      If any court or competent authority finds that any provision of this Privacy
                      Policy (or part of any provision) is invalid, illegal or unenforceable, that
                      provision or part-provision will, to the extent required, be deemed to be
                      deleted, and the validity and enforceability of the other provisions of this
                      Privacy Policy will not be affected.
                    </p>
                  </li>

                  <li>
                    <p>
                      Unless otherwise agreed, no delay, act or omission by a party in exercising
                      any right or remedy will be deemed a waiver of that, or any other, right or
                      remedy.
                    </p>
                  </li>

                  <li>
                    <p>
                      This Agreement will be governed by and interpreted according to the law of
                      England and Wales. All disputes arising under the Agreement will be subject to
                      the exclusive jurisdiction of the English and Welsh courts.
                    </p>
                  </li>
                </ol>

                <h5>Changes to This Privacy Policy</h5>
                <ol start="29">
                  <li>
                    <p>
                      GUIDANCE PLUS NI LTD reserves the right to change this Privacy Policy as we
                      may deem necessary from time to time or as may be required by law. Any changes
                      will be immediately posted on the Website and you are deemed to have accepted
                      the terms of the Privacy Policy on your first use of the Website following the
                      alterations.
                    </p>
                  </li>
                </ol>

                <p>You may contact GUIDANCE PLUS NI LTD by email at ceo@guidanceplusni.com.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PrivacyPolicy;
