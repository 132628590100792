import React, { useEffect } from "react";

const BookAppointmet = ({ pageTitle }) => {
  useEffect(() => {
    document.title = `${pageTitle ?? ""} - GuidanceplusNI`;
  }, []);

  return (
    <div className="education-main-section">
      <div className="container">
        <div className="row">
          <aside className="col-md-12 education-sidebar">
            <div className="education-fancy-title">
              <i className="fa fa-graduation-cap"></i>
              <span>Book An Appointment</span>
            </div>
            <div className="education-contact-us-form appointment-form">
              <form>
                <ul>
                  <li>
                    <input type="text" value="" placeholder="First name" />
                    <i className="fa fa-user"></i>
                  </li>
                  <li>
                    <input type="text" value="" placeholder="Last name" />
                    <i className="fa fa-user"></i>
                  </li>

                  <li>
                    <input type="text" value="" placeholder="Email*" />
                    <i className="fa fa-envelope"></i>
                  </li>
                  <li>
                    <input type="text" value="" placeholder="Phone" />
                    <i className="fa fa-phone"></i>
                  </li>

                  <li>
                    <select>
                      <option value="" className="placeholder" disabled="" selected="selected">
                        Service Requirement
                      </option>
                      <option value="Visit Visa">Visit Visa</option>
                      <option value="Student Visa">Student Visa</option>
                      <option value="Immigration">Immigration</option>
                      <option value="Business Visa">Business Visa</option>
                    </select>
                  </li>
                  <li>
                    <input type="date" value="" placeholder="Appointment Date" />
                    <input type="time" value="" placeholder="Appointment Time" />
                  </li>

                  <li className="message-box">
                    <textarea name="comment" placeholder="Subject"></textarea>
                  </li>

                  <li className="message-box">
                    <textarea name="comment" placeholder="Description"></textarea>
                  </li>
                  <li>
                    <label>
                      <input type="submit" value="Send" />
                    </label>
                  </li>
                </ul>
              </form>
            </div>
          </aside>
        </div>
      </div>
    </div>
  );
};

export default BookAppointmet;
