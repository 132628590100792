import React, { useState, useEffect } from "react";
import axios from "axios";

const ContactUs = ({ pageTitle }) => {
  useEffect(() => {
    document.title = `${pageTitle ?? ""} - GuidanceplusNI`;
  }, []);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [errors, setErrors] = useState({});
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  // Simple validation function
  const validateForm = () => {
    let tempErrors = {};
    if (!formData.name) tempErrors.name = "Name is required";
    if (!formData.email) {
      tempErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      tempErrors.email = "Email is invalid";
    }
    if (!formData.message) tempErrors.message = "Message is required";
    return tempErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    try {
      const response = await axios.post("/api/submit_form.php", formData);
      if (response.data.success) {
        setSuccessMessage("Thank you! Our team will get back to you.");
        setFormData({ name: "", email: "", message: "" });
        setErrors({});
      } else {
        setErrorMessage(response.data?.message);
      }
    } catch (error) {
      console.error("There was an error submitting the form", error);
    }
  };

  return (
    <div className="education-main-section">
      <div className="container">
        <div className="row">
          <div className="col-md-3">
            <div className="education-contact-us">
              <ul>
                <li>
                  <h6>Phone</h6>
                  <span>028 9013 6332 - +44 7507272479</span>
                  <i className="fa fa-phone"></i>
                </li>

                <li>
                  <h6>Email</h6>
                  <a href="mailto:info@guidanceplusni.com">info@guidanceplusni.com</a>
                  <i className="fa fa-envelope"></i>
                </li>
                <li>
                  <h6>Location</h6>
                  <span>115 Cregagh Road Belfast BT6 0LA 028 9013 6332</span>
                  <i className="fa fa-map-marker"></i>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-md-9">
            <div className="education-contact-us-map">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1012.726973165517!2d-5.899368823267618!3d54.58231391440904!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48610910df1959e1%3A0x4ec962eaf9e8cf35!2s115%20Cregagh%20Rd%2C%20Belfast%20BT6%200LA%2C%20UK!5e0!3m2!1sen!2sin!4v1726836014408!5m2!1sen!2sin"
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
                className="contact-map"
              ></iframe>
            </div>
          </div>
          <div className="col-md-12">
            <div className="education-fancy-title">
              <i className="fa fa-graduation-cap"></i>
              <span>get it touch</span>
              <h2>message now</h2>
            </div>
            <div className="education-contact-us-form">
              <form onSubmit={handleSubmit}>
                <ul>
                  <li>
                    <input
                      type="text"
                      value={formData.name}
                      onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                    />
                    <i className="fa fa-user"></i>
                    {errors.name && <p>{errors.name}</p>}
                  </li>
                  <li>
                    <input
                      type="email"
                      value={formData.email}
                      onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                    />
                    <i className="fa fa-globe"></i>
                    {errors.email && <p>{errors.email}</p>}
                  </li>
                  <li className="message-box">
                    <textarea
                      name="comment"
                      placeholder="Message"
                      value={formData.message}
                      onChange={(e) => setFormData({ ...formData, message: e.target.value })}
                    ></textarea>
                    {errors.message && <p>{errors.message}</p>}
                  </li>
                  <li>
                    <label>
                      <input type="submit" value="Send" />
                    </label>
                  </li>
                </ul>
              </form>
              {successMessage && <p>{successMessage}</p>}
              {errorMessage && <p>{errorMessage}</p>}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
