import React from "react";
import { COURSE_DETAILS } from "./CourseDetailsData";

const Courses = () => {
  const courseList = COURSE_DETAILS;
  return (
    <div className="education-main-section">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="education-course education-course-grid">
              <ul className="row">
                {courseList.map((course) => (
                  <li className="col-md-4">
                    <figure>
                      <a href={course?.link}>
                        <img src={course?.image} alt="Guiguidanceplusni" />
                        <i className="fa fa-link"></i>
                      </a>
                    </figure>
                    <div className="education-course-grid-text">
                      <h5>
                        <a href={course?.link}>{course?.name}</a>
                      </h5>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Courses;
