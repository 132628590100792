import React from "react";

import aboutUsImage1 from "../extra-images/immigration-service-01.webp";
import aboutUsImage2 from "../extra-images/banner-thumb-2.png";
// import courseImg1 from "../extra-images/courses-modren-img1.jpg";
// import commentOption from "../extra-images/comment-option-img.jpg";
// import coursesImg2 from "../extra-images/courses-modren-img2.jpg";
// import coursesImg3 from "../extra-images/courses-modren-img3.jpg";
// import testimonialimg from "../extra-images/testimonial-fig-img.jpg";
// import eventImg1 from "../extra-images/event-medium-img1.jpg";
// import eventImg2 from "../extra-images/event-medium-img2.jpg";
// import eventImg3 from "../extra-images/event-medium-img3.jpg";
// import eventImg4 from "../extra-images/event-medium-img4.jpg";
// import modernGallery1 from "../extra-images/modern-gallery-img1.jpg";
// import modernGallery2 from "../extra-images/modern-gallery-img2.jpg";
// import modernGallery3 from "../extra-images/modern-gallery-img3.jpg";
// import modernGallery4 from "../extra-images/modern-gallery-img4.jpg";
// import modernGallery5 from "../extra-images/modern-gallery-img5.jpg";
// import modernGallery6 from "../extra-images/modern-gallery-img6.jpg";

import Courses from "./Courses";

const Home = () => {
  return (
    <>
      <div className="education-main-section">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="education-fancy-title">
                <i className="fa fa-graduation-cap"></i>
                <span>what we provide</span>
                <h2>Our Story</h2>
              </div>
            </div>
            <div className="col-md-6">
              <div className="education-about-services text-justify">
                <p>
                  Recognized as a leading international education and employment consulting service
                  in Uk, Guidance Plus NI partners with more than 1000 prestigious universities,
                  institutions, colleges and worldwide. We provide unparalleled guidance and
                  unmatched mentorship to students, guiding them towards exceptional educational
                  opportunities.
                </p>
                <p>
                  As a premier international recruitment consulting firm, we specialize in sourcing
                  and selecting top talent for clients worldwide. We serve as partners to
                  multinational corporations, startups, and small businesses that are either scaling
                  up or expanding their operations.
                </p>
                <p>
                  With over a decade of experience in student recruitment and Job placements,
                  Guidance Plus NI is a trusted one-stop solution for educational and career
                  consultation for students and employees at numerous top universities and companies
                  worldwide. Our advisory board comprises top faculties and industry experts from
                  the UK and USA.
                </p>

                <p>
                  Our expertise allows us to provide affordable and effective career counselling
                  services to help employees and students uncover their skills, aptitudes, and
                  interests. We then guide them in choosing a career path that aligns with their
                  talents. Our professionally structured services focus on mentoring students and
                  assisting them in pursuing higher education based on their determination and
                  abilities.
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <img src={aboutUsImage1} alt="Guiguidanceplusni" className="education-about-image" />
            </div>
          </div>
        </div>
      </div>

      <Courses />

      <div className="education-main-section education-modren-coursefull">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="education-fancy-title">
                <i className="fa fa-graduation-cap"></i>
                <h2>Who we are</h2>
              </div>
              <div className="education-about-services text-justify">
                <p>
                  At Guidance Plus NI, the seeds of potential are deeply rooted, permeating all
                  aspects of our services. Our team is a blend of expertise in consulting, research,
                  technology, industry connections, and best practices, enhancing our educational
                  consulting endeavours. Guided by a skilled advisory board with extensive
                  qualifications and experience, we are driven towards achieving success.
                </p>
              </div>
            </div>

            <div className="col-md-12">
              <div className="education-fancy-title">
                <i className="fa fa-graduation-cap"></i>
                <h2>International Recruitment Solutions</h2>
              </div>
              <div className="education-about-services text-justify">
                <p>
                  We specialize in facilitating a seamless shift from working in Uk to working
                  abroad. Our expertise spans various sectors and industries globally, with a strong
                  presence as a top Hospitality recruitment consultancy in UK. Opting for our
                  services goes beyond engaging an international recruitment agency; you gain access
                  to a myriad of additional benefits that enhance your experience.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="education-main-section">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="education-about-services home-choose">
                <div className="education-fancy-title">
                  <i className="fa fa-graduation-cap"></i>
                  <h2>When You Choose Us You Get</h2>
                </div>

                <div className="education-service education-service-grid">
                  <ul className="row">
                    <li className="col-md-6">
                      <div className="education-service-grid-wrap">
                        <i className=" icon-old-fashion-briefcase"></i>
                        <h5>
                          <a href="#">Proficiency and Experience</a>
                        </h5>
                        <p>
                          Our crew is composed of industry specialists with substantial
                          understanding and expertise in the field.
                        </p>
                        <a href="#" className="education-readmore-btn">
                          Read More <i className="fa fa-arrow-circle-o-right"></i>
                        </a>
                      </div>
                    </li>
                    <li className="col-md-6">
                      <div className="education-service-grid-wrap">
                        <i className="icon-calculate"></i>
                        <h5>
                          <a href="#">Tailored Solutions</a>
                        </h5>
                        <p>
                          We recognize that each client is special, so we design methods that
                          address particular needs.
                        </p>
                        <a href="#" className="education-readmore-btn">
                          Read More <i className="fa fa-arrow-circle-o-right"></i>
                        </a>
                      </div>
                    </li>
                    <li className="col-md-6">
                      <div className="education-service-grid-wrap">
                        <i className="icon-flask-outline"></i>
                        <h5>
                          <a href="#">Result-driven Approach</a>
                        </h5>
                        <p>
                          Our priority is on producing concrete outcomes that will allow you to move
                          ahead with assurance.
                        </p>
                        <a href="#" className="education-readmore-btn">
                          Read More <i className="fa fa-arrow-circle-o-right"></i>
                        </a>
                      </div>
                    </li>
                    <li className="col-md-6">
                      <div className="education-service-grid-wrap">
                        <i className="icon-calculate"></i>
                        <h5>
                          <a href="#">Dedication to Perfection</a>
                        </h5>
                        <p>
                          We aim for the finest standards in every detail we do to ensure
                          reliability and craftsmanship.
                          <br />
                        </p>
                        <a href="#" className="education-readmore-btn">
                          Read More <i className="fa fa-arrow-circle-o-right"></i>
                        </a>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="education-main-section education-counter-img">
        <span className="counter-transparent"></span>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="education-counter">
                <ul className="row">
                  <li className="col-md-3">
                    <i className="icon-old-fashion-briefcase"></i>
                    <h4 id="word-count1">10+</h4>
                    <span>Years of experience</span>
                  </li>
                  <li className="col-md-3">
                    <i className=" icon-people3"></i>
                    <h4 id="word-count2">5,000+</h4>
                    <span>Students Counseled</span>
                  </li>
                  <li className="col-md-3">
                    <i className=" icon-bell"></i>
                    <h4 id="word-count3">1,000+</h4>
                    <span>University options</span>
                  </li>
                  <li className="col-md-3">
                    <i className="icon-earth"></i>
                    <h4 id="word-count4">1,000+</h4>
                    <span>Students per year</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="education-main-section">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="education-fancy-title">
                <i className="fa fa-graduation-cap"></i>
                <h2>WHAT DO WE DO?</h2>
              </div>
            </div>
            <div className="col-md-6">
              <img src={aboutUsImage2} alt="Guiguidanceplusni" className="education-about-image" />
            </div>
            <div className="col-md-6">
              <div className="education-about-services text-justify">
                <p>
                  Securing admission to an international university requires meticulous attention to
                  detail at every step. Even the smallest errors can jeopardize your aspirations of
                  studying abroad.
                </p>

                <p>
                  Guidance Plus NI is a reputable student visa and employment consultancy
                  collaborating with over 500 top Companies, 1000 top- tier professional
                  universities and colleges worldwide. We provide robust support to enhance your
                  chances of receiving an offer from your desired company or university.
                </p>

                <p>
                  With genuine enthusiasm, we guide students through the admission process,
                  simplifying procedures and facilitating successful enrolment in a variety of
                  professional courses. Our team methodically assists aspirants in selecting
                  educational institutions that align with their interests.
                </p>
              </div>
            </div>
            <div className="col-md-12">
              <p>
                Now dream more and fulfil every aspiration with the most trusted overseas education
                consultants in the heart of Northern Ireland. We empower students by providing them
                with the right knowledge and thereby opening up global opportunities. Whatever your
                dreams and aspirations are, we provide a solution tailor-made just for you.
              </p>

              <p>
                Unleash your dreams and achieve all your aspirations with the leading overseas
                education consultants in the United Kingdom. Our team empowers students by equipping
                them with the necessary knowledge to access global opportunities. No matter what
                your dreams may be, we offer personalized solutions to help you reach your goals.
              </p>

              <p>
                GUIDANCE PLUS NI is the topmost UK, Ireland, France and German study abroad
                consultant in the United Kingdom. We are one of the most reputed overseas education
                consultation facilitators in Belfast representing over 1000 + premier educational
                Universities, Institutions, and colleges worldwide to impart impeccable guidance and
                matchless mentoring to aspirants, leading them towards excellent education
                opportunities.
              </p>

              <p>
                GUIDANCEPLUS NI stands out as the premier study abroad consultant for the UK,
                Ireland,India, France, and Germany in Belfast. As a highly esteemed overseas
                education facilitator in the region, we work with over 1000 prestigious
                universities, institutions, and colleges globally. Our unmatched guidance and
                support pave the way for aspirants to access exceptional educational opportunities
                and reach their full potential.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
