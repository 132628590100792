import React, { useEffect } from "react";
import pageThumb from "../extra-images/employer-thumbnail.webp";

const Employer = ({ pageTitle }) => {
  useEffect(() => {
    document.title = `${pageTitle ?? ""} - GuidanceplusNI`;
  }, []);

  return (
    <>
      <div className="education-main-section">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="">
                <div className="education-section-heading">
                  <h2>Sponsorship Licence</h2>
                </div>
              </div>
            </div>

            <div className="col-md-8">
              <p>
                If your organisation intends to hire non-UK resident workers, a valid sponsorship
                licence is required. Our experts specialise in sponsor licence applications and
                compliance, ensuring a smooth and successful process.
              </p>
              <p>
                To employ non-UK resident workers, UK employers must obtain a sponsorship licence
                from the Home Office. This licence is essential for hiring under various work
                routes, including the Skilled Worker visa, Scale-up visa, and Global Business
                Mobility routes. Without a valid sponsorship licence, organisations cannot sponsor
                migrant workers.
              </p>

              <div className="education-section-heading">
                <h2>Types of Sponsorship Licences</h2>
              </div>

              <h3>Sponsorship licences are categorized into two main types:</h3>

              <ol>
                <li>
                  <p>
                    Worker Sponsor Licence: This licence permits the recruitment of overseas
                    nationals for skilled employment in the UK, whether short-term, long-term, or
                    permanent, depending on the specific immigration route. Sub-categories under
                    this licence include:
                  </p>
                  <ol>
                    <li>Skilled Worker route</li>
                    <li>Senior or Specialist Worker route</li>
                    <li>Minister of Religion route</li>
                    <li>International Sportsperson route</li>
                  </ol>
                </li>

                <li>
                  <p>
                    Temporary Worker Sponsor Licence: This licence is designed for specific types of
                    temporary employment. Sub-categories include:
                  </p>
                  <ol>
                    <li>Scale-up Worker route</li>
                    <li>Creative Worker route</li>
                    <li>Charity Worker route</li>
                    <li>Religious Worker route</li>
                    <li>Government Authorised Exchange route</li>
                    <li>International Agreement route</li>
                    <li>Seasonal Worker route</li>
                  </ol>
                </li>

                <li>
                  <p>
                    Additionally, certain routes under the Global Business Mobility umbrella are
                    classified as ‘Temporary Worker’ routes, such as:
                  </p>
                  <ol>
                    <li>Graduate Trainee</li>
                    <li>Service Supplier</li>
                    <li>UK Expansion Worker</li>
                    <li>Secondment Worker </li>
                  </ol>
                </li>
              </ol>
            </div>
            <div className="col-md-4">
              <img src={pageThumb} alt="" className="img-thumbnail" />
            </div>
            <div className="col-md-12">
              <div className="col-md-12">
                <div className="education-section-heading">
                  <h2>Compliance and Responsibilities</h2>
                </div>

                <p>
                  Holding a sponsorship licence entails significant responsibilities. Employers must
                  be fully aware of their immigration duties and have robust processes and systems
                  in place to meet these requirements and maintain records as evidence of
                  compliance. The Home Office can investigate licence holders at any time. If
                  compliance breaches are found, the Home Office has the authority to downgrade,
                  suspend, or revoke sponsorship licences, which can severely impact an
                  organisation’s ability to hire skilled migrant workers and affect the sponsored
                  workers’ permission to stay and work in the UK.
                </p>

                <div className="education-section-heading">
                  <h2>How Guidance Plus NI Can Assist</h2>
                </div>
                <p>
                  Guidance Plus NI’s business immigration specialists offer comprehensive support
                  for organisations at any stage of the sponsorship licence lifecycle. Whether
                  you’re applying for your first licence, renewing an existing one, addressing a
                  refused application, or seeking ongoing compliance management, we can help. Our
                  services include:
                </p>

                <ul>
                  <li>
                    Assisting with initial considerations to determine the most appropriate type of
                    sponsorship application and suitable key personnel.
                  </li>
                  <li>Drafting your online sponsor licence application.</li>
                  <li>Helping you collate the necessary supporting documentation.</li>
                  <li>
                    Auditing your HR and recruitment systems and procedures in preparation for a
                    UKVI visit.
                  </li>
                  <li>Training staff on their compliance duties and responsibilities.</li>
                  <li>
                    Advising on the UKVI 'genuineness' test and assessing whether a prospective
                    employee satisfies the points criteria before issuing a Certificate of
                    Sponsorship.
                  </li>
                  <li>
                    Guidance on sponsorship-related fees, both for the application and ongoing.
                  </li>
                  <li>Advice on assigning Certificates of Sponsorship to skilled visa workers. </li>
                  <li>
                    Advising on alternatives if you decide not to make a sponsor licence
                    application.
                  </li>
                  <li>Licence renewal applications.</li>
                  <li>Dealing with sponsor licence suspensions and revocations.</li>
                </ul>

                <p>
                  Our dedicated seamlessly work with your in-house HR team or manage the entire
                  licence application and compliance process on your behalf.
                </p>

                <p>
                  For expert advice on sponsorship licences, contact Guidance Plus NI Sponsor
                  License Team.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Employer;
