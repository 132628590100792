import React, { useEffect } from "react";

const GlobalEducation = ({ pageTitle }) => {
  useEffect(() => {
    document.title = `${pageTitle ?? ""} - GuidanceplusNI`;
  }, []);

  return (
    <>
      <div className="education-main-section">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="education-about-services">
                <p>
                  The growth of educational system all around the world has been very drastic and it
                  is everyone dream to endeavour the course of their liking and build the career for
                  the better future of our society and collective mankind.
                </p>
                <div className="education-section-heading">
                  <h2>Why Study Abroad?</h2>
                </div>

                <p>Studying abroad offers numerous benefits, including:</p>
                <ul>
                  <li>
                    High-Quality Education – Access to globally ranked universities and advanced
                    learning methodologies.
                  </li>
                  <li>
                    Global Career Opportunities – Enhances job prospects with international
                    exposure.
                  </li>
                  <li>Cultural Exposure – Experience new cultures, languages, and lifestyles.</li>
                  <li>
                    Networking & Connections – Build a global professional and academic network.
                  </li>
                  <li>
                    Personal Growth – Develop independence, adaptability, and critical thinking
                    skills.
                  </li>
                </ul>

                <div className="education-section-heading">
                  <h2>Top Study Destinations</h2>
                </div>
                <ul>
                  <li>USA – Home to Ivy League universities and top research programs.</li>
                  <li>
                    UK – Renowned for institutions like Oxford, Cambridge, and strong academic
                    traditions.
                  </li>
                  <li>Canada – Offers affordable education and post-study work opportunities.</li>
                  <li>Australia – High-quality education with a strong focus on research.</li>
                  <li>
                    Europe (Germany, France, Netherlands, etc.) – Tuition-free or low-cost education
                    in public universities.
                  </li>
                </ul>

                <div className="education-section-heading">
                  <h2>Popular Courses for International Students</h2>
                </div>
                <ul>
                  <li>Business & Management</li>
                  <li>Engineering & Technology </li>
                  <li>Computer Science & IT</li>
                  <li>Medicine & Healthcare</li>
                  <li>Social Sciences & Humanities</li>
                </ul>
                <div className="education-section-heading">
                  <h2>How Guidance Plus NI can help</h2>
                </div>
                <ul>
                  <li>University Selection – Matching students with the right institution.</li>
                  <li>Application Assistance – Helping with paperwork, essays, and SOPs.</li>
                  <li>Visa Guidance – Ensuring smooth visa processing.</li>
                  <li>Scholarships & Financial Aid – Advising on funding opportunities.</li>
                  <li>
                    Pre-Departure & Post-Arrival Support – Assistance with accommodation, travel,
                    and settling in.
                  </li>
                </ul>

                <p>Start Your Study Abroad Journey Today!</p>
                <p>
                  Looking for expert guidance? Our overseas education consultants and counsellors
                  can assist you choose the right course, university, and country that aligns with
                  your goals and career plans.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GlobalEducation;
