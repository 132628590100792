import React, { useEffect } from "react";

import aboutUsImage1 from "../extra-images/aboutus-image-1.jpg";
import postImg from "../extra-images/post-text-img.png";

const AboutUs = ({ pageTitle }) => {
  useEffect(() => {
    document.title = `${pageTitle ?? ""} - GuidanceplusNI`;
  }, []);

  return (
    <>
      <div className="education-main-section pt-2">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="education-about-services">
                <h2>About Guidance Plus NI</h2>
                <p className="text-justify">
                  At Guidance Plus NI, we are dedicated to offering individuals and organizations
                  with expert advice and customized remedies. With years of expertise and a thorough
                  awareness of market trends, we assist our clients in navigating obstacles, seizing
                  opportunities, and achieving their goal with assurance.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="education-main-section education-section-postfull">
        <span className="post-transparent"></span>
        <div className="container">
          <div className="row">
            <div className="col-md-5">
              <div className="education-post-image">
                <img src={postImg} alt="Guiguidanceplusni" />
              </div>
            </div>
            <div className="col-md-7">
              <div className="education-post-text">
                <h3>Who We Are</h3>
                <p>
                  Guidance Plus NI was launched with the goal of providing dependable, prudent, and
                  results- oriented consulting services. Our team is built of dedicated and
                  passionate people with vast knowledge, guaranteeing that we provide holistic and
                  reliable services. We are proud of our ability to simplify complex procedures,
                  making them more convenient and manageable.
                </p>

                <h3>What We Do</h3>
                <p>
                  We specialize in providing personalized solutions across an extensive spectrum of
                  industries, assisting our clients in making rational choices and uncovering their
                  full potential. Guidance Plus NI is there to help you with professional advice,
                  strategic planning, and personal assistance at all times. We adopt a client-first
                  approach. We spend the time to learn your specific requirements and customize our
                  services appropriately. Our core values of honesty, growth, and excellence guide
                  all we do, guaranteeing our clients benefit from the finest possible service.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="education-main-section pt-2">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="education-about-services">
                <p className="text-justify">
                  Immigration services are at the heart of what we do. We specialize in offering
                  full assistance to individuals, families, and organizations as they get around the
                  perils of global mobility. Whether you want to study, work, or settle abroad, our
                  skilled staff assures a smooth procedure by providing specialized advice on visa
                  applications, legal requirements, and compliance issues. With extensive
                  understanding of immigration rules and processes, we simplify the process,
                  allowing our clients to realize their aspirations of foreign relocation with trust
                  and ease.
                </p>

                <div className="education-service education-service-grid">
                  <h3>Why Choose Us?</h3>
                  <ul className="row">
                    <li className="col-md-6">
                      <div className="education-service-grid-wrap">
                        <i className=" icon-old-fashion-briefcase"></i>
                        <h5>
                          <a href="#">Proficiency and Experience</a>
                        </h5>
                        <p>
                          Our crew is composed of industry specialists with substantial
                          understanding and expertise in the field.
                        </p>
                        <a href="#" className="education-readmore-btn">
                          Read More <i className="fa fa-arrow-circle-o-right"></i>
                        </a>
                      </div>
                    </li>
                    <li className="col-md-6">
                      <div className="education-service-grid-wrap">
                        <i className="icon-calculate"></i>
                        <h5>
                          <a href="#">Tailored Solutions</a>
                        </h5>
                        <p>
                          We recognize that each client is special, so we design methods that
                          address particular needs.
                        </p>
                        <a href="#" className="education-readmore-btn">
                          Read More <i className="fa fa-arrow-circle-o-right"></i>
                        </a>
                      </div>
                    </li>
                    <li className="col-md-6">
                      <div className="education-service-grid-wrap">
                        <i className="icon-flask-outline"></i>
                        <h5>
                          <a href="#">Result-driven Approach</a>
                        </h5>
                        <p>
                          Our priority is on producing concrete outcomes that will allow you to move
                          ahead with assurance.
                        </p>
                        <a href="#" className="education-readmore-btn">
                          Read More <i className="fa fa-arrow-circle-o-right"></i>
                        </a>
                      </div>
                    </li>
                    <li className="col-md-6">
                      <div className="education-service-grid-wrap">
                        <i className="icon-calculate"></i>
                        <h5>
                          <a href="#">Dedication to Perfection</a>
                        </h5>
                        <p>
                          We aim for the finest standards in every detail we do to ensure
                          reliability and craftsmanship.
                          <br />
                        </p>
                        <a href="#" className="education-readmore-btn">
                          Read More <i className="fa fa-arrow-circle-o-right"></i>
                        </a>
                      </div>
                    </li>
                  </ul>

                  <p>
                    Guidance Plus NI is far more than just a consulting firm; we are your partners
                    in triumph.
                  </p>
                  <p>Let us guide you through your path with lucidity, strategy, and certainty.</p>
                  <p>We aim at connecting careers and building futures.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutUs;
