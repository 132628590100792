import React, { useEffect } from "react";

const Terms = ({ pageTitle }) => {
  useEffect(() => {
    document.title = `${pageTitle ?? ""} - GuidanceplusNI`;
  }, []);

  return (
    <>
      <div className="education-main-section">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="education-about-services">
                <h5>Introduction</h5>
                <p>
                  These terms and conditions apply between you, the User of this Website (including
                  any sub-domains, unless expressly excluded by their own terms and conditions), and
                  Guidance Plus NI Ltd, the owner and operator of this Website. Please read these
                  terms and conditions carefully, as they affect your legal rights. Your agreement
                  to comply with and be bound by these terms and conditions is deemed to occur upon
                  your first use of the Website. If you do not agree to be bound by these terms and
                  conditions, you should stop using the Website immediately.
                </p>
                <p>
                  In these terms and conditions, User or Users means any third party that accesses
                  the Website and is not either (i) employed by Guidance Plus NI Ltd and acting in
                  the course of their employment or (ii) engaged as a consultant or otherwise
                  providing services to Guidance Plus NI Ltd and accessing the Website in connection
                  with the provision of such services.
                </p>
                <p>
                  You must be at least 18 years of age to use this Website. By using the Website and
                  agreeing to these terms and conditions, you represent and warrant that you are at
                  least 18 years of age.
                </p>

                <h5>Intellectual Property and Acceptable Use</h5>
                <ol>
                  <li>
                    <p>
                      All Content included on the Website, unless uploaded by Users, is the property
                      of Guidance Plus NI Ltd, our affiliates or other relevant third parties. In
                      these terms and conditions, Content means any text, graphics, images, audio,
                      video, software, data compilations, page layout, underlying code and software
                      and any other form of information capable of being stored in a computer that
                      appears on or forms part of this Website, including any such content uploaded
                      by Users. By continuing to use the Website you acknowledge that such Content
                      is protected by copyright, trademarks, database rights and other intellectual
                      property rights. Nothing on this site shall be construed as granting, by
                      implication, estoppel, or otherwise, any license or right to use any
                      trademark, logo or service mark displayed on the site without the owner's
                      prior written permission
                    </p>
                  </li>

                  <li>
                    <p>
                      You may, for your own personal, non-commercial use only, do the following:
                    </p>
                    <ol>
                      <li>retrieve, display and view the Content on a device</li>
                      <li>
                        download and store the Content in electronic form on a disk (but not on any
                        server or other storage device connected to a network)
                      </li>
                      <li>print one copy of the Content</li>
                    </ol>
                  </li>

                  <li>
                    <p>
                      You must not otherwise reproduce, modify, copy, distribute or use for
                      commercial purposes any Content without the written permission of Guidance
                      Plus NI Ltd.
                    </p>
                  </li>

                  <h5>Prohibited Use</h5>

                  <li>
                    <p>You may not use the Website for any of the following purposes:</p>
                    <ul>
                      <li>
                        in any way which causes, or may cause, damage to the Website or interferes
                        with any other person's use or enjoyment of the Website;
                      </li>
                      <li>
                        in any way which is harmful, unlawful, illegal, abusive, harassing,
                        threatening or otherwise objectionable or in breach of any applicable law,
                        regulation, governmental order;
                      </li>
                      <li>
                        making, transmitting or storing electronic copies of Content protected by
                        copyright without the permission of the owner.
                      </li>
                    </ul>
                  </li>

                  <h5>Links to Other Websites</h5>

                  <li>
                    <p>
                      This Website may contain links to other sites. Unless expressly stated, these
                      sites are not under the control of Guidance Plus NI Ltd or that of our
                      affiliates.
                    </p>
                  </li>

                  <li>
                    <p>
                      We assume no responsibility for the content of such Websites and disclaim
                      liability for any and all forms of loss or damage arising out of the use of
                      them.
                    </p>
                  </li>

                  <li>
                    <p>
                      The inclusion of a link to another site on this Website does not imply any
                      endorsement of the sites themselves or of those in control of them.
                    </p>
                  </li>

                  <h5>Availability of the Website and Disclaimers</h5>

                  <li>
                    <p>
                      Any online facilities, tools, services or information that Guidance Plus NI
                      Ltd makes available through the Website (the Service) is provided "as is" and
                      on an "as available" basis. We give no warranty that the Service will be free
                      of defects and/or faults. To the maximum extent permitted by the law, we
                      provide no warranties (express or implied) of fitness for a particular
                      purpose, accuracy of information, compatibility and satisfactory quality.
                      Guidance Plus NI Ltd is under no obligation to update information on the
                      Website.
                    </p>
                  </li>

                  <li>
                    <p>
                      Whilst Guidance Plus NI Ltd uses reasonable endeavours to ensure that the
                      Website is secure and free of errors, viruses and other malware, we give no
                      warranty or guaranty in that regard and all Users take responsibility for
                      their own security, that of their personal details and their computers.
                    </p>
                  </li>

                  <li>
                    <p>
                      Guidance Plus NI Ltd accepts no liability for any disruption or
                      non-availability of the Website.
                    </p>
                  </li>

                  <li>
                    <p>
                      Guidance Plus NI Ltd reserves the right to alter, suspend or discontinue any
                      part (or the whole) of the Website including, but not limited to, any products
                      and/or services available. These terms and conditions shall continue to apply
                      to any modified version of the Website unless it is expressly stated
                      otherwise.
                    </p>
                  </li>

                  <h5>Limitation of Liability</h5>

                  <li>
                    <p>
                      Nothing in these terms and conditions will: (a) limit or exclude our or your
                      liability for death or personal injury resulting from our or your negligence,
                      as applicable; (b) limit or exclude our or your liability for fraud or
                      fraudulent misrepresentation; or (c) limit or exclude any of our or your
                      liabilities in any way that is not permitted under applicable law.
                    </p>
                  </li>

                  <li>
                    <p>
                      We will not be liable to you in respect of any losses arising out of events
                      beyond our reasonable control.
                    </p>
                  </li>

                  <li>
                    <p>
                      To the maximum extent permitted by law, Guidance Plus NI Ltd accepts no
                      liability for any of the following:
                    </p>
                    <ol>
                      <li>
                        any business losses, such as loss of profits, income, revenue, anticipated
                        savings, business, contracts, goodwill or commercial opportunities;
                      </li>
                      <li>loss or corruption of any data, database or software; </li>
                      <li>any special, indirect or consequential loss or damage.</li>
                    </ol>
                  </li>

                  <h5>General</h5>

                  <li>
                    <p>
                      You may not transfer any of your rights under these terms and conditions to
                      any other person. We may transfer our rights under these terms and conditions
                      where we reasonably believe your rights will not be affected.
                    </p>
                  </li>

                  <li>
                    <p>
                      These terms and conditions may be varied by us from time to time. Such revised
                      terms will apply to the Website from the date of publication. Users should
                      check the terms and conditions regularly to ensure familiarity with the then
                      current version.
                    </p>
                  </li>

                  <li>
                    <p>
                      These terms and conditions contain the whole agreement between the parties
                      relating to its subject matter and supersede all prior discussions,
                      arrangements or agreements that might have taken place in relation to the
                      terms and conditions.
                    </p>
                  </li>

                  <li>
                    <p>
                      The Contracts (Rights of Third Parties) Act 1999 shall not apply to these
                      terms and conditions and no third party will have any right to enforce or rely
                      on any provision of these terms and conditions.
                    </p>
                  </li>

                  <li>
                    <p>
                      If any court or competent authority finds that any provision of these terms
                      and conditions (or part of any provision) is invalid, illegal or
                      unenforceable, that provision or part-provision will, to the extent required,
                      be deemed to be deleted, and the validity and enforceability of the other
                      provisions of these terms and conditions will not be affected.
                    </p>
                  </li>

                  <li>
                    <p>
                      Unless otherwise agreed, no delay, act or omission by a party in exercising
                      any right or remedy will be deemed a waiver of that, or any other, right or
                      remedy.
                    </p>
                  </li>

                  <li>
                    <p>
                      This Agreement shall be governed by and interpreted according to the law of
                      England and Wales and all disputes arising under the Agreement (including
                      non-contractual disputes or claims) shall be subject to the exclusive
                      jurisdiction of the English and Welsh courts.
                    </p>
                  </li>

                  <h5>Guidance Plus NI Ltd Details</h5>

                  <li>
                    <p>
                      Guidance Plus NI Ltd is a company incorporated in with registered number
                      NI696438 whose registered address is Guidance Plus NI Ltd, 115 Cregagh Road,
                      Belfast, BT6 0LA and it operates the Website www.guidanceplusni.com. You can
                      contact Guidance Plus NI Ltd by email on admin@guidanceplusni.com.
                    </p>
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Terms;
